.top_main_title {
  /* background-color: orangered; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}

.editing_controls {
  /* background-color: orangered; */
  display: flex;
  align-items: center;
}

.editing_controls_btns {
  margin-left: 12px;
  padding: 5px 15px;
  /* width: 180px; */
  text-align: center;
  transition: all 300ms ease;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.editing_controls_btns:hover {
  opacity: 0.6;
  letter-spacing: 1px;
}

.enable_editing {
  background-color: rgba(50, 53, 255, 0.829);
  color: #f5f5f5;
}
.disable_editing {
  background-color: rgb(252, 107, 54);
  color: #f5f5f5;
}
.save_edited {
  background-color: rgba(0, 151, 8, 0.829);
  color: #f5f5f5;
}

.information_quadrants {
  height: 90%;
  margin-top: 20px;
  /* background-color: #f5f5f5; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.lebler {
  color: #f5f5f5;
  font-size: 12px;
  margin-right: 20px;
  width: 50px;
}

.personal_quadrant {
  padding: 8px;
  margin: 8px 10px;
}

.information_quadrants input {
  border: none;
  outline: none;
  padding: 6px 12px;
}

.quad_names {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quad_other_names .lebler {
  margin-left: 8px;
}
.quad_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.quad_surnames {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quad_surnames input {
  flex: 1;
  /* width: 90%; */
}

.identity {
  flex: 1;
}

.gender {
  padding: 6px 12px;
  border: none;
  outline: none;
  width: 200px;
  margin-right: 60%;
}

.dob_container {
  display: flex;
}

.quad_dob_cont {
  margin-right: 10px;
}

.quad_dob_year {
  margin-right: 0;
}

.addressing_quadrant {
  padding: 8px;
  margin: 8px 10px;
}

.addressing_quadrant .lebler {
  width: 110px;
}

.addressing_quadrant textarea {
  border: none;
  outline: none;
  flex: 1;
  width: 100%;
}

.quad_address_box {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.quad_contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.occupation_quadrant {
  padding: 8px;
  margin: 8px 10px;
}

.quad_occupation_boxes {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 200px;
}

.quad_occupation_boxes .lebler {
  width: 100px;
}

.quad_occupation_boxes input {
  flex: 1;
}

.culture_quadrant {
  padding: 8px;
  margin: 8px 10px;
}

.quad_culture_box {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
