.unassign_teacher_container {
    background-color: rgba(36, 40, 80, 0.911);
    position: absolute;
    z-index: 2000;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.confirm_box_container {
    background-color: #fff;
    width: 300px;
    height: 400px;
    padding: 12px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}

.warning_title {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 8px;
    text-transform: uppercase;
    margin-bottom: 12px;
}

.warning_title p{
    font-weight: bold;
    font-size: 20px;
    margin-left: 30px;
}

.warning_descrip p{
    font-weight: bold;
    margin-bottom: 12px;
}

.un_info_box {
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.unassign_controls {
    margin-top: 50%;
    display: flex;
    justify-content: space-between;
}

.unassign_controls button {
    border: none;
    outline: none;
    padding: 12px;
    width: 130px;
    border-radius: 6px;
    text-transform: uppercase;
    transition: all 300ms cubic-bezier(0.96, 0.35, 0.1, 0.66);
}

.unassign_controls button:hover {
    letter-spacing: 1px;
    opacity: .7;
}

.un_unassign {
    background-color: orangered;
    color: #f5f5f5;
}

.un_cancel {
    background-color: yellow;
}