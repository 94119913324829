.all_classes{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.classes_main_title {
    background-color: #e4e4e4;
    width: 79vw;
    padding: 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.classes_main_title p{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 22px;
    color: rgb(36, 40, 80);
}

.select_grade_bar{
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select_grade_title{
    background-color: rgb(118, 145, 224);
    color: #fff;
    padding: 15px 20px;
}

.select_grade_choices {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
}

.grade_sin_box{
    background-color: transparent;
    border: 1px solid rgb(1, 161, 231);
    padding: 15px;
    width: 50px;
    height: 50px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.grade_sin_box:hover {
    transform: translateY(-5px);
}

.sin_class {
    border-radius: 5px;
    min-width: 200px;
    max-width: 250px;
    margin-top: 25px;
    margin-bottom: 12px;
    box-shadow: -3px -3px 8px rgba(0, 0, 0, 0.219), 3px 3px 8px rgba(0, 0, 0, 0.253);
}

.sin_class_title_bar {
    background-color: rgb(36, 40, 80);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0;
}

.sin_class_title_bar p{
    color: #fff;
}

.sin_class_title_bar button {
    width: 70px;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
    padding: 3px 8px;
    transition: 0.5s;
    cursor: pointer;
}

.sin_class_title_bar button:hover {
    letter-spacing: 2px;
    background-color: #fff;
    color: #111;
}

.sin_class_details{
    border-radius: 0 0 5px 5px;
    background-color: #f5f5f5;
    padding: 10px;
}

.sin_class_username{
    text-transform: uppercase;
    font-weight: bold;
}

.sin_class_userType {
    font-size: 12px;
}

.sin_class_unassign_btn {
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sin_class_unassign{
    border: none;
    outline: none;
    padding: 8px 12px;
    background-color: tomato;
    color: #fff;
    border-radius: 6px;
    transition: 0.5s;
    cursor: pointer;
}

.sin_class_unassign:hover {
    letter-spacing: 2px;
    background-color: orangered;
    color: #f5f5f5;
}