* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

html {
  min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: linear-gradient(
    35deg,
    rgba(41, 171, 226, 0.85),
    rgba(0, 146, 69, 0.85)
  );
  background-position: center;
  background-size: cover;
  /* background-repeat: no-repeat; */
}
