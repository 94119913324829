.forgot_password_container {
    background-color: rgb(36, 40, 80);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot_password_box {
    background-color: #fff;
    height: 500px;
    width: 350px;
    border-radius: 6px;
    box-shadow: -3px -3px 8px rgb(0, 0, 0), 2px 2px 4px rgba(255, 255, 255, 0.486);
    padding: 12px;
    position: relative;
}

.forgot_title {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
    color: orangered;
    border-left: 6px solid red;
    padding-left: 5px;
}

.forgot_descrip {
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 12px;
}

.forgot_input_box {
    margin-top: 30px;
    margin-bottom: 12px;
    padding: 15px;
    width: 100%;
    height: 60px;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot_input_title {
    position: absolute;
}

.forgot_input {
    padding: 12px;
    width: 80%;
    background-color: transparent;
    border: 1px solid rgb(36, 40, 80);
    border-radius: 6px;
    outline: none;
    position: absolute;
}

.forgot_input:focus .forgot_input_title {
    transform: translateY(50px);
}

.forgot_submitter {
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    border: 1px solid orangered;
    background-color: orangered;
    color: #f5f5f5;
    padding: 8px;
    width: 150px;
    border-radius: 6px;
    transition: all 300ms ease;
    font-weight: bold;
}

.forgot_submitter:hover {
    letter-spacing: 1px;
    background-color: rgba(255, 68, 0, 0.39);
    color: rgb(36, 40, 80);
    border-color: rgb(36, 40, 80);
}