.threeBtns {
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.threeBtns div {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.171);
  margin-right: 10px;
  /* border: 2px solid #fff; */
  padding: 5px 12px;
  cursor: pointer;
  border-bottom: 5px solid transparent;
  transition: all 300ms ease;
}

.threeBtns div:hover {
  opacity: 0.6;
}

.learning_disabilities_title_box {
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 8px 15px;
}

.learning_disabilities_content_box {
  /* background-color: red; */
  height: 60vh;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.learning_disabilities_content_box textarea {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin-top: 8px;
  padding: 8px 15px;
}
