.student_select_contain {
  padding: 12px;
}

.title_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.exit_select {
  margin-right: 50px;
  width: 150px;
  text-align: center;
  background-color: orangered;
  color: #f5f5f5;
  padding: 8px 20px;
  border: 2px solid orangered;
  border-radius: 8px;
  filter: drop-shadow(-3px -3px 4px rgba(255, 255, 255, 0.288))
    drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.473));
  cursor: pointer;
  transition: all 300ms ease;
  text-decoration: none;
}

.exit_select:hover {
  background-color: #f5f5f5;
  color: orangered;
  letter-spacing: 2px;
}

.main_ssc_title {
  font-weight: normal;
  text-transform: uppercase;
  color: #f5f5f5;
  font-size: 30px;
}

.main_ssc_label {
  font-weight: lighter;
  font-size: 25px;
  color: #fff;
  border-left: 8px solid #fff;
  padding-left: 8px;
  margin-left: 15px;
  margin-bottom: 20px;
}

.act_classes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-gap: 20px;
}

.a_class_box {
  background-color: #f5f5f5;
  width: 250px;
  height: 200px;
  padding: 8px;
  position: relative;
  border-radius: 8px;
  box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.212),
    2px 2px 2px rgba(0, 0, 0, 0.212);
  transition: all 300ms ease;
}

.a_class_box:hover {
  background: linear-gradient(rgba(255, 255, 255, 0.11), rgba(0, 0, 0, 0.11));
}

.a_class_box:hover .open_btn {
  border: 2px solid #fff;
}

.open_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgb(1, 161, 231);
  color: #fff;
  border: none;
  padding: 8px 20px;
  border-radius: 8px;
  border: 2px solid #fff;
  transition: all 300ms ease;
  cursor: pointer;
}

.open_btn:hover {
  background-color: rgb(36, 40, 80);
}

.num_of {
  margin-left: 20px;
}
