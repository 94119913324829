.giLeft_btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  align-self: flex-start;
}

.giLeft_btns button {
  width: 280px;
  padding: 10px 8px;
  margin-bottom: 5px;
  border: none;
  outline: none;
  font-size: 12px;
  transition: all 300ms ease;
  border: 2px solid #fff;
}

.giLeft_btns button:hover {
  opacity: 0.7;
}

.giContainer {
  display: flex;
}

.giRightContents {
  /* background-color: #fff; */
  flex: 1;
  height: 80vh;
  padding: 8px;
  padding-top: 0;
}

.gi_title_box {
  background-color: #f5f5f5;
  padding: 8px 12px;
  margin-bottom: 12px;
}

.gi_content_title {
  color: #fff;
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 15px;
}

.gi_content_textarea {
  position: relative;
  height: 55px;
}

.gi_content_textarea textarea {
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 3px;
  font-size: 12px;
}
