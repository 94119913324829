.psychometric_data_titles {
  background-color: rgba(0, 0, 0, 0.151);
  border: 1px solid #fff;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-areas: "date date name name name grade tester tester rewards rewards rewards rewards rewards rewards";
}
 
.psychometric_data_titles div {
  color: #fff;
  font-size: 13px;
  padding: 2px;
  /* margin-right: 2px; */
}

.psy_date {
  grid-area: date;
  text-align: center;
  border-left: 1px solid #fff;
}
.psy_name {
  grid-area: name;
  text-align: center;
  border-left: 1px solid #fff;
}
.psy_grade {
  grid-area: grade;
  text-align: center;
  border-left: 1px solid #fff;
}
.psy_tester {
  grid-area: tester;
  text-align: center;
  border-left: 1px solid #fff;
}
.psy_remarks {
  grid-area: rewards;
  text-align: center;
  border-left: 1px solid #fff;
}

.psych_data_box input {
  border: none;
  outline: none;
  padding: 2px;
}

.input_pych_date {
  width: calc(14.2857% - 2px);
  margin-right: 2px;
  margin-bottom: 5px;
}
.input_pych_name {
  width: calc(21.42857% - 2px);
  margin-right: 2px;
}
.input_pych_grade {
  width: calc(7.142857% - 2px);
  margin-right: 2px;
}
.input_pych_tester {
  width: calc(14.2857% - 2px);
  margin-right: 2px;
}
.input_pych_remarks {
  width: calc(42.8571% - 2px);
  margin-right: 2px;
}

.pych_data_list_container {
  margin-top: 30px;
}
